import * as React from "react";

import {
  BasketIcon,
  ChartIcon,
  CloseIcon,
  IconSize,
  InvoicesIcon,
  RequestsIcon,
  TechnicalDocumentation,
} from "src/icons";
import {
  ICMReportNotification,
  IDocumentNotification,
  INewCommentNotification,
  INotificationContext,
  NotificationType,
} from "src/models/notifications";
import {
  ICombinedRequest,
  IDelivery,
  IEquipment,
  IInstallation,
  INotification,
  IPurchase,
} from "online-services-types";
import { IRouteParams, getPathWithParams, getRoutes } from "src/routes";

import { Container } from "src/design-system/Container";
import { Disclaimer } from "src/design-system/Tokens/typography";
import { FlexContainer } from "src/design-system/Container";
import { IIconBase } from "src/icons/IconBase";
import { Link } from "react-router-dom";
import { ThemedProps } from "src/design-system/Theme/theme";
import { breakpoints } from "src/design-system/Tokens/breakpoints";
import { defaultBorderRadius } from "src/design-system/Tokens/tokens";
import { filterDiscardableNotifications } from "./NotificationListComponent";
import styled from "styled-components";
import { translateString } from "src/util/localization";

enum NotificationDataMainType {
  ASI = "ASI",
  TR = "TR",
  MAN = "MAN",
  SPC = "SPC",
}

const technicalKnowledgeTitles: Record<NotificationDataMainType, string> = {
  [NotificationDataMainType.ASI]: "notificationList.title.bulletinUpdated",
  [NotificationDataMainType.TR]: "notificationList.title.techReportUpdated",
  [NotificationDataMainType.MAN]: "notificationList.title.manualUpdated",
  [NotificationDataMainType.SPC]: "notificationList.title.sparePartsUpdated",
};

type INotificationData = {
  icon: React.ComponentType<IIconBase>;
  path: string;
  topHeading?: () => string;
  subHeading?: () => string;
  title: () => string;
};
export interface INotificationListItemProps {
  notification: INotification;
  installations: IInstallation[];
  equipments: IEquipment[];
  notificationContext: INotificationContext;
  onRequestCloseSidebar(): void;
  discardAndSetNotificationAsRead(ids: string[], notificationContext: INotificationContext): void;
}

interface IDiscardable {
  $isDiscarded?: boolean;
}

interface IRoundIconProps {
  $radius: number;
  $mobileRadius?: number;
}

const NotificationItemContainer = styled(FlexContainer).attrs({ $margin: [1, 0], $padding: 1 })<
  IDiscardable & ThemedProps
>`
  border-radius: ${defaultBorderRadius};

  ${(props: IDiscardable & ThemedProps) =>
    props.$isDiscarded
      ? `text-decoration: line-through; background: ${props.theme.navigationButton.background}; opacity: 0.75;`
      : `background: ${props.theme.navigationButton.background};`}
`;

const Title = styled(Container).attrs({ $margin: [1, 0] })``;

const SmallContent = styled(Disclaimer)`
  display: block;
`;

const IconContainer = styled.div<IDiscardable & ThemedProps & IRoundIconProps>`
  align-self: center;
  margin: 0 auto;
  width: ${(props) => props.$mobileRadius || props.$radius}em;
  height: ${(props) => props.$mobileRadius || props.$radius}em;

  @media (min-width: ${breakpoints.mobileLarge}px) {
    width: ${(props) => props.$radius}em;
    height: ${(props) => props.$radius}em;
  }
  background: ${(props) => {
    return props.theme.navigationButton.background;
  }};
  border-radius: 50%;
  position: relative;
  display: inline-flex;
  align-items: center;

  & > * {
    margin: auto;
  }
`;

const Content = styled(Container).attrs({ $margin: [0, 3] })`
  flex: 1;
`;

export const getNotificationInfo = (
  notification: INotification,
  installations: IInstallation[],
  equipments: IEquipment[]
) => {
  const genericRequestTopHeading = () => {
    return (notification.objectData as ICombinedRequest)?.subject || "";
  };
  const genericInvoiceTopHeading = () => {
    return (notification.data as { invoiceId: string })?.invoiceId || "";
  };
  const genericPurchaseTopHeading = () => {
    return (notification.objectData as IPurchase)?.yourReference || "";
  };
  const genericRequestSubHeading = () => {
    return (notification.objectData as ICombinedRequest)?.subject || "";
  };
  const genericCommentSubHeading = () => {
    const commentNotification = notification as INewCommentNotification;
    return commentNotification.data ? commentNotification.data.message : "";
  };
  const genericDocumentInstallationTopHeading = () => {
    const notificationInstallations = (notification as IDocumentNotification).data
      ? (notification as IDocumentNotification).data.installationIds
      : null;
    return installations
      .filter(
        (installation) => notificationInstallations !== null && notificationInstallations.includes(installation.id)
      )
      .map((inst) => inst.name)
      .join(", ");
  };
  const genericRequestInstallationTopHeading = () => {
    return installations
      .filter((installation) => (notification.objectData as ICombinedRequest)?.installationId === installation.id)
      .map((inst) => inst.name)
      .join(", ");
  };
  const cmReportTopHeading = () => {
    const cmReportNotification = notification as ICMReportNotification;
    const notificationInstallations = cmReportNotification.data ? cmReportNotification.data.installationIds : null;
    let key;
    switch (cmReportNotification.type) {
      case NotificationType.NewCMReport:
        key = "notificationList.title.newCMReport";
        break;
      case NotificationType.NewCMReportBudget:
        key = "notificationList.title.newCMReportBudget";
        break;
      case NotificationType.NewCMReportDMP:
        key = "notificationList.title.newCMReportDMP";
        break;
      case NotificationType.NewCMReportQuarterly:
        key = "notificationList.title.newCMReportQuarterly";
        break;
      default:
        key = "notificationList.title.newCMReport";
    }
    const installationNames = installations
      .filter(
        (installation) => notificationInstallations !== null && notificationInstallations.includes(installation.id)
      )
      .map((inst) => inst.name)
      .join(", ");
    return translateString(key, {
      installationName: installationNames,
    });
  };
  const findInstallationByEquipment = (equipmentId: string): IInstallation | undefined => {
    const foundEquipment = equipments.find((equipment) => equipment.id === equipmentId);

    if (!foundEquipment) {
      return undefined;
    }

    const foundInstallation = installations.find((installation) => installation.id === foundEquipment.installationId);

    if (!foundInstallation) {
      return undefined;
    }

    return foundInstallation;
  };
  const getInstallationIdFromSalesOrder = (salesOrderNotification: INotification) => {
    const installation = findInstallationByEquipment(salesOrderNotification.objectId);
    if (!installation) {
      return undefined;
    }

    return installation.id;
  };
  const newRequestText = translateString("notificationList.title.newRequest");

  const newTechnicalKnowledge = (isCritical = false): INotificationData => {
    const notificationData = (notification as IDocumentNotification).data;
    const titleTranslationKey: string =
      technicalKnowledgeTitles[notificationData?.mainType] ?? "notificationList.title.documentUpdated";
    const routeParams: IRouteParams = notificationData
      ? { documentId: encodeURIComponent(notificationData.documentNumber) }
      : {};

    return {
      icon: TechnicalDocumentation,
      title: () =>
        translateString(isCritical ? "notificationList.title.criticalBulletinUpdated" : titleTranslationKey, {
          title: notificationData?.title,
          document: notificationData?.subType,
        }),
      topHeading: genericDocumentInstallationTopHeading,
      path: getPathWithParams(getRoutes().Documents, routeParams),
    };
  };

  const urlMapping: Record<NotificationType, INotificationData> = {
    [NotificationType.NewTechnicalKnowledge]: newTechnicalKnowledge(),
    [NotificationType.NewTechnicalKnowledgeCritical]: newTechnicalKnowledge(true),
    [NotificationType.NewTechRequestComment]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.newComment"),
      topHeading: genericRequestTopHeading,
      subHeading: genericCommentSubHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewCSCComment]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.newComment"),
      topHeading: genericRequestTopHeading,
      subHeading: genericCommentSubHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewCSCAttachment]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.newAttachment"),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewTechRequestAttachment]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.newAttachment"),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewSWRRecommendation]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.newRecommendation"),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Documents, { requestId: notification.objectId }),
    },
    [NotificationType.SalesOrderItemClassificationAvailable]: {
      icon: BasketIcon,
      title: () => translateString("notificationList.title.classificationAvailable"),
      topHeading: genericPurchaseTopHeading,
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.NewCSCRequest]: {
      icon: RequestsIcon,
      title: () => newRequestText,
      topHeading: genericRequestInstallationTopHeading,
      subHeading: genericRequestSubHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewTechRequest]: {
      icon: RequestsIcon,
      title: () => newRequestText,
      topHeading: genericRequestInstallationTopHeading,
      subHeading: genericRequestSubHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewTechRequestByWartsila]: {
      icon: RequestsIcon,
      title: () => newRequestText,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewTechRequestDistributor]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.newRequestDistributor"),
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewSparePartClaim]: {
      icon: BasketIcon,
      title: () => translateString("notificationList.title.newSparePartClaim"),
      topHeading: genericRequestInstallationTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewCMReport]: {
      icon: ChartIcon,
      title: cmReportTopHeading,
      path: getPathWithParams(getRoutes().Reports, { reportId: notification.objectId }),
    },
    [NotificationType.NewCMReportBudget]: {
      icon: ChartIcon,
      title: cmReportTopHeading,
      path: getPathWithParams(getRoutes().Reports, { reportId: notification.objectId }),
    },
    [NotificationType.NewCMReportDMP]: {
      icon: ChartIcon,
      title: cmReportTopHeading,
      path: getPathWithParams(getRoutes().Reports, { reportId: notification.objectId }),
    },
    [NotificationType.NewCMReportQuarterly]: {
      icon: ChartIcon,
      title: cmReportTopHeading,
      path: getPathWithParams(getRoutes().Reports, { reportId: notification.objectId }),
    },
    [NotificationType.WaitingForReply]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.waitingForReply"),
      topHeading: genericRequestTopHeading,
      subHeading: genericCommentSubHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.WaitingForRating]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.waitingForRating"),
      topHeading: genericRequestTopHeading,
      subHeading: genericCommentSubHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.ResolvedSparePartClaim]: {
      icon: BasketIcon,
      title: () => translateString("notificationList.title.resolvedSparePartClaim"),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewTechRequestSolutionPlan]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.techRequestSolutionPlan"),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.WarrantyContactUpdated]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.warrantyContactUpdated"),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewInvoiceAvailable]: {
      icon: InvoicesIcon,
      title: () => translateString("notificationList.title.NewInvoiceAvailable"),
      topHeading: genericInvoiceTopHeading,
      path: getPathWithParams(getRoutes().NewInvoice, {
        id: (notification.data as { invoiceId: string })?.invoiceId,
      }),
    },
    [NotificationType.NewWarrantyClaim]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.newWarrantyClaim"),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewWarrantyClaimComment]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.newWarrantyClaimComment"),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.NewWarrantyClaimAttachment]: {
      icon: RequestsIcon,
      title: () => translateString("notificationList.title.newWarrantyClaimAttachment"),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },

    [NotificationType.WarrantyClaimDeliveryReadyToBeCollected]: {
      icon: RequestsIcon,
      title: () =>
        translateString("notificationList.title.WarrantyClaimDeliveryReadyToBeCollected", {
          deliveryId: (notification.data as IDelivery).id,
        }),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.WarrantyClaimDeliveryDispatched]: {
      icon: RequestsIcon,
      title: () =>
        translateString("notificationList.title.WarrantyClaimDeliveryDispatched", {
          deliveryId: (notification.data as IDelivery).id,
        }),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.WarrantyClaimDeliveryDocumentAvailable]: {
      icon: RequestsIcon,
      title: () =>
        translateString("notificationList.title.WarrantyClaimDeliveryDocumentAvailable", {
          deliveryId: (notification.data as IDelivery).id,
          documentType: (notification.data as IDelivery).notificationDocumentType || "",
        }),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.WarrantyClaimDeliveryDelivered]: {
      icon: RequestsIcon,
      title: () =>
        translateString("notificationList.title.WarrantyClaimDeliveryDelivered", {
          deliveryId: (notification.data as IDelivery).id,
        }),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },
    [NotificationType.WarrantyClaimDeliveryCollected]: {
      icon: RequestsIcon,
      title: () =>
        translateString("notificationList.title.WarrantyClaimDeliveryCollected", {
          deliveryId: (notification.data as IDelivery).id,
        }),
      topHeading: genericRequestTopHeading,
      path: getPathWithParams(getRoutes().Requests, { requestId: notification.objectId }),
    },

    [NotificationType.EquipmentRunningHoursUpdate]: {
      icon: BasketIcon,
      title: () => translateString("notificationList.title.equipmentRunningHoursUpdate"),
      topHeading: () => {
        const foundInstallation = findInstallationByEquipment(notification.objectId);

        if (!foundInstallation) {
          return "";
        }

        return foundInstallation.name;
      },
      path: getPathWithParams(getRoutes().RunningHours, {
        installationId: getInstallationIdFromSalesOrder(notification) || "",
      }),
    },
    [NotificationType.NewSalesOrder]: {
      icon: BasketIcon,
      topHeading: genericPurchaseTopHeading,
      title: () => translateString("notificationList.title.newSalesOrder"),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.NewServiceQuotation]: {
      icon: BasketIcon,
      topHeading: genericPurchaseTopHeading,
      title: () => translateString("notificationList.title.newServiceQuotation"),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.ServiceQuotationIsAboutToExpire]: {
      icon: BasketIcon,
      topHeading: genericPurchaseTopHeading,
      title: () => translateString("notificationList.title.quotationIsAboutToExpire"),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.SalesOrderConfirmed]: {
      icon: BasketIcon,
      topHeading: genericPurchaseTopHeading,
      title: () => translateString("notificationList.title.salesOrderConfirmed"),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.ServiceQuotationQuoted]: {
      icon: BasketIcon,
      topHeading: genericPurchaseTopHeading,
      title: () => translateString("notificationList.title.serviceQuotationQuoted"),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.SalesOrderProformaAvailable]: {
      icon: BasketIcon,
      topHeading: genericPurchaseTopHeading,
      title: () => translateString("notificationList.title.salesOrderProformaAvailable"),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.ServiceQuotationProformaAvailable]: {
      icon: BasketIcon,
      title: () => translateString("notificationList.title.serviceQuotationProformaAvailable"),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },

    [NotificationType.SalesOrderDeliveryReadyToBeCollected]: {
      icon: BasketIcon,
      title: () =>
        translateString("notificationList.title.SalesOrderDeliveryReadyToBeCollected", {
          deliveryId: (notification.data as IDelivery).id,
        }),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.SalesOrderDeliveryCollected]: {
      icon: BasketIcon,
      title: () =>
        translateString("notificationList.title.SalesOrderDeliveryCollected", {
          deliveryId: (notification.data as IDelivery).id,
        }),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.SalesOrderDeliveryDispatched]: {
      icon: BasketIcon,
      title: () =>
        translateString("notificationList.title.SalesOrderDeliveryDispatched", {
          deliveryId: (notification.data as IDelivery).id,
        }),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.SalesOrderDeliveryDelivered]: {
      icon: BasketIcon,
      title: () =>
        translateString("notificationList.title.SalesOrderDeliveryDelivered", {
          deliveryId: (notification.data as IDelivery).id,
        }),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.SalesOrderDeliveryDocumentAvailable]: {
      icon: BasketIcon,
      title: () =>
        translateString("notificationList.title.SalesOrderDeliveryDocumentAvailable", {
          documentType: (notification.data as IDelivery).notificationDocumentType || "",
          deliveryId: (notification.data as IDelivery).id,
        }),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.SalesOrderEtaDateChanged]: {
      icon: BasketIcon,
      topHeading: genericPurchaseTopHeading,
      title: () => translateString("notificationList.title.salesOrderEtaDateChanged"),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
    [NotificationType.SalesOrderCollectionDateChanged]: {
      icon: BasketIcon,
      topHeading: genericPurchaseTopHeading,
      title: () => translateString("notificationList.title.salesOrderCollectionDateChanged"),
      path: getPathWithParams(getRoutes().SparePartsItem, { orderId: notification.objectId }),
    },
  };

  if (urlMapping[notification.type]) {
    return urlMapping[notification.type];
  }

  return undefined;
};

export const NotificationListItem = (props: INotificationListItemProps) => {
  const linkInfo = getNotificationInfo(props.notification, props.installations, props.equipments);
  const Icon = linkInfo ? linkInfo.icon : RequestsIcon;
  return (
    <Link to={linkInfo ? linkInfo.path : ""} onClick={props.onRequestCloseSidebar}>
      <NotificationItemContainer $isDiscarded={props.notification.discarded}>
        <IconContainer $isDiscarded={props.notification.discarded} $radius={3} $mobileRadius={3}>
          <Icon size={IconSize.Medium} />
        </IconContainer>
        <Content>
          {linkInfo && linkInfo.topHeading && <SmallContent>{linkInfo.topHeading()}</SmallContent>}
          <Title data-test-notification-title>{linkInfo && linkInfo.title()}</Title>
          {linkInfo && linkInfo.subHeading && <SmallContent>{linkInfo.subHeading()}</SmallContent>}
        </Content>
        {!props.notification.discarded && filterDiscardableNotifications([props.notification]).length > 0 && (
          <IconContainer
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              props.discardAndSetNotificationAsRead([props.notification.id], props.notificationContext);
            }}
            $isDiscarded={props.notification.discarded}
            $radius={2}
          >
            <CloseIcon size={IconSize.XSmall} />
          </IconContainer>
        )}
      </NotificationItemContainer>
    </Link>
  );
};
